import { forwardRef, useState, useContext, useRef } from "react";
import "./projetGallery.scss";
import { Link, useLocation } from "react-router-dom";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import { FADE_DURATION } from "../../helpers";
import HOVER_SALES from "../../utils/img/HOVER_SALES.svg";

const LinkRef = forwardRef((props, ref) => (
  <Link {...props} ref={ref}>
    {props.children}
  </Link>
));

const MotionLink = motion(LinkRef);

export const ProjetGallery = forwardRef((props, ref) => {
  const { section, projet, template, bgColor } = props;
  console.log("projet", projet);

  // Les données de projet sont déjà dans la structure correcte, pas besoin de destructurer data
  const items = Array.isArray(projet) ? projet : [projet];

  const { filtersData, lang } = useContext(StoreContext);

  const { pathname } = useLocation();

  const containerRef = useRef();
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end 75vh"],
  });

  const count = 12;

  const [limit, setLimit] = useState(count);

  const windowHeight = window.innerHeight;

  const viewportOptions = {
    margin: `0px 0px -${windowHeight / 4}px 0px`,
    once: true,
  };

  const filteredItems = () => {
    let data = items || [];
    
    // Vérifier si nous avons des données à filtrer
    if (!data || data.length === 0 || !data[0] || !data[0][lang]) {
      console.log("Pas de données à filtrer ou format incorrect", data);
      return [];
    }
  
    for (const [key, value] of Object.entries(filtersData)) {
      if (key === "search") {
        data = data.filter((el) =>
          el[lang]?.title?.data?.toLowerCase().includes(value.toLowerCase())
        );
      } else if (value && value.length > 0) {
        data = data.filter((el) => {
          const info = el[lang] && el[lang]["info"] && el[lang]["info"].data;
  
          if (info) {
            const infoData = typeof info === 'string' ? JSON.parse(info) : info;
  
            // Filter items based on both genre and cible
            return value.some((filterItem) => {
              const genreValues = infoData.filter((item) => {
                return (
                  item[lang] &&
                  item[lang]["title"] &&
                  (item[lang]["title"]["slug"] === "genre" ||
                  item[lang]["title"]["slug"] === "genero" ||
                  item[lang]["title"]["slug"] === "type") &&
                  item[lang]["value"] &&
                  item[lang]["value"]["data"]
                );
              });
  
              const cibleValues = infoData.filter((item) => {
                return (
                  item[lang] &&
                  item[lang]["title"] &&
                  (item[lang]["title"]["slug"] === "cible" ||
                   item[lang]["title"]["slug"] === "target" ||
                   item[lang]["title"]["slug"] === "objetivo" ||
                   item[lang]["title"]["slug"] === "objectivo") &&
                  item[lang]["value"] &&
                  item[lang]["value"]["data"]
                );
              });
              
              const genreMatches = genreValues.some((genreValue) => {
                const genreData = genreValue[lang]["value"]["data"];
                const genreArray = genreData.split(",").map((g) => g.trim());
                return genreArray.includes(filterItem.label);
              });
  
              const cibleMatches = cibleValues.some((cibleValue) => {
                const cibleData = cibleValue[lang]["value"]["data"];
                const cibleArray = cibleData.split(",").map((c) => c.trim());
                return cibleArray.includes(filterItem.label);
              });
  
              return genreMatches || cibleMatches;
            });
          }
  
          return false;
        });
      }
    }
  
    return data;
  };
  
  const filteredData = filteredItems();
  console.log("filteredData", filteredData);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest >= 1 && filteredData && limit < filteredData.length) setLimit(limit + count);
  });

  return (
    <section
      className={`pageSection projetGallery ${template} ${
        bgColor === "white"
          ? "bg-white"
          : bgColor === "blue"
          ? "bg-blue"
          : bgColor === "rose"
          ? "bg-pink"
          : ""
      }`}
      ref={ref}
    >
      <div className="gallery" ref={containerRef}>
        {filteredData && filteredData.length > 0 ? (
          filteredData.slice(0, limit).map((el) => {
            if (!el || !el[lang]) {
              console.log("Élément ou langue manquant", el);
              return null;
            }
            
            const { title = {}, image = {} } = el[lang] ?? {};
            
            try {
              const { data: infoData = [] } = el[lang].info || {};
              const infoDataJSON = Array.isArray(infoData) ? infoData : JSON.parse(infoData);

              const formatObject = infoDataJSON.find(
                (entry) => (entry[lang]?.title?.slug === "format" || entry[lang]?.title?.slug === "formato" ||  entry[lang]?.title?.slug === "format")
              );

              const genreObject = infoDataJSON.find(
                (entry) => (entry[lang]?.title?.slug === "genre" ||entry[lang]?.title?.slug === "genero" ||  entry[lang]?.title?.slug === "type")
              );
              
              const cibleObject = infoDataJSON.find(
                (entry) => (entry[lang]?.title?.slug === "target" ||entry[lang]?.title?.slug === "objetivo" ||  entry[lang]?.title?.slug === "objectivo" ||  entry[lang]?.title?.slug === "cible")
              );

              return (
                <MotionLink
                  key={el.id}
                  className="projet-item"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: FADE_DURATION }}
                  viewport={viewportOptions}
                  to={`${pathname}/${title.slug}`}
                >
                  <img
                    src={image.data ? image.data.path : ""}
                    alt={image.data ? image.data.alt : "not found"}
                    className="main-img"
                    style={{
                      "--letter-mask": `url("${HOVER_SALES}")`,
                    }}
                  />

                  <em className="title">{title.data}</em>

                  <p className="desc">
                    {formatObject &&
                      formatObject[lang]?.value.data &&
                      formatObject[lang]?.value.data + " | "}
                    {genreObject &&
                      genreObject[lang]?.value.data &&
                      genreObject[lang]?.value.data + " | "}
                    {cibleObject &&
                      cibleObject[lang]?.value.data &&
                      cibleObject[lang]?.value.data}
                  </p>
                </MotionLink>
              );
            } catch (error) {
              console.error("Erreur lors du rendu d'un élément", error, el);
              return null;
            }
          })
        ) : (
          <p>{trad[lang].no_result}</p>
        )}
      </div>
    </section>
  );
});
